import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Body, {
  Size as BodySize,
  LetterCase as BodyLetterCase,
  Spacing as BodySpacing,
} from 'components/Typography/Body';
import Spinner from 'components/Common/Spinner';
import Button, {
  Kind as ButtonKind,
  Size as ButtonSize,
} from 'components/Common/Button';
import PodcastSummary from 'components/Pages/Podcasts/PodcastSummary';
import {
  selectAcceptPodcastState,
  selectEditPendingDocumentsPodcastsState,
  selectFetchPendingPodcastsState,
  selectRejectPodcastState,
  selectSendToNetworkPodcastState,
} from 'state/selectors/podcasts';
import { selectAuthenticatedUserState } from 'state/selectors/auth';
import {
  acceptPodcast,
  clearAllPodcastsState,
  clearPodcastsState,
  editPendingDocument,
  fetchPendingPodcasts,
  rejectPodcast,
  sendToNetworkPodcast,
} from 'state/actions/podcasts';
import { fetchHostingProviders } from 'state/actions/generals';
import { sendToNetworkPodcastInit } from 'state/actionCreators/podcasts';
import Toast from 'components/Common/Toast';
import useModal from 'hooks/useModal';
import ModalType from 'enums/modal/modalType.enum';
import Modal from 'components/Common/Modal';
import getPodcastData from 'utils/podcasts/getPodcastData';
import PodcastFullInformation from 'components/Pages/Podcasts/PodcastFullInformation';
import PodcastRejectReason from 'components/Pages/Podcasts/PodcastRejectReason';
import Confirmation from 'components/Common/Confirmation';
import { PODCASTS_PAGINATION } from 'constants/podcasts';
import SelectInput from 'components/Common/Select';
import Input from 'components/Common/Input';
import { sortingOptions } from 'utils/podcasts/getSortingOptions';
import filterPodcastsBySearchValue from 'utils/podcasts/filterPodcastsBySearchValue';
import EditPodcastAudienceEstimate from 'components/Pages/Podcasts/EditPodcastAudienceEstimate';
import EditPodcastDownloadsEstimate from 'components/Pages/Podcasts/EditPodcastDownloadsEstimate';

import networks from 'enums/podcasts/networks.enum';
import classes from './PendingPodcasts.module.scss';

const PendingPodcasts = () => {
  const dispatch = useDispatch();

  const { user } = useSelector(selectAuthenticatedUserState, shallowEqual);

  const {
    error: errorPendingPodcasts,
    success: successPendingPodcasts,
    loading: loadingPendingPodcasts,
    pendingPodcasts,
    lastPaginationPodcast,
  } = useSelector(selectFetchPendingPodcastsState, shallowEqual);

  const {
    error: errorAcceptPodcast,
    success: successAcceptPodcast,
    loading: loadingAcceptPodcast,
  } = useSelector(selectAcceptPodcastState, shallowEqual);

  const {
    error: errorRejectPodcast,
    success: successRejectPodcast,
    loading: loadingRejectPodcast,
  } = useSelector(selectRejectPodcastState, shallowEqual);

  const {
    error: errorSendToNetworkPodcast,
    success: successSendToNetworkPodcast,
    loading: loadingSendToNetworkPodcast,
  } = useSelector(selectSendToNetworkPodcastState, shallowEqual);

  const {
    loading: editPendingDocumentLoading,
    success: editPendingDocumentSuccess,
  } = useSelector(selectEditPendingDocumentsPodcastsState, shallowEqual);

  const first = PODCASTS_PAGINATION;
  const lastItemPagination = useMemo(() => lastPaginationPodcast, [
    lastPaginationPodcast,
  ]);

  const [podcastsToShow, setPodcastsToShow] = useState([]);
  const [fetchMorePodcasts, setFetchMorePodcasts] = useState(false);
  const [fetchPodcastsChanges, setFetchPodcastsChanges] = useState(false);
  const [noMorePodcasts, setNoMorePodcasts] = useState(false);
  const [podcastIdToDelete, setPodcastIdToDelete] = useState(null);
  const [selectedSort, setSelectedSort] = useState(sortingOptions[2]);
  const [changedSorting, setChangedSorting] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const {
    modal,
    onOpenModalHandler,
    onCloseModalHandler,
    setModal,
  } = useModal();

  useEffect(() => {
    setFetchMorePodcasts(true);
    const lastItemPaginationSort = changedSorting ? null : lastItemPagination;

    if (changedSorting) {
      setNoMorePodcasts(false);
    }

    dispatch(
      fetchPendingPodcasts({
        first,
        lastItemPaginationSort,
        selectedSort,
        searchValue,
        network: user.network,
      })
    );
    setChangedSorting(false);

    return () => {
      dispatch(clearAllPodcastsState());
    };
  }, [changedSorting]);

  useEffect(() => {
    if (successPendingPodcasts && fetchMorePodcasts) {
      setFetchMorePodcasts(false);
      if (pendingPodcasts?.length > 0) {
        setPodcastsToShow((prevState) => [...prevState, ...pendingPodcasts]);
        dispatch(fetchHostingProviders());
      }

      if (pendingPodcasts?.length < PODCASTS_PAGINATION) {
        setNoMorePodcasts(true);
      }
      dispatch(clearPodcastsState());
    }
  }, [successPendingPodcasts, pendingPodcasts, fetchMorePodcasts, dispatch]);

  useEffect(() => {
    if (fetchPodcastsChanges && pendingPodcasts.length > 0) {
      setPodcastsToShow((prev) => {
        const newPodcasts = [];

        prev.forEach((podcast) => {
          const podcastIsBeingShown = pendingPodcasts.find(
            (p) => p.uid === podcast.uid
          );
          if (podcastIsBeingShown) {
            return newPodcasts.push(podcastIsBeingShown);
          }

          return newPodcasts.push(podcast);
        });
        return newPodcasts;
      });
      setFetchPodcastsChanges(false);
    }
  }, [fetchPodcastsChanges, pendingPodcasts]);

  useEffect(() => {
    if (successAcceptPodcast || successRejectPodcast) {
      onCloseModalHandler();
      setPodcastsToShow((prevState) =>
        prevState.filter((doc) => doc.uid !== podcastIdToDelete)
      );
      setPodcastIdToDelete(null);
    }
  }, [successAcceptPodcast, successRejectPodcast, podcastIdToDelete]);

  useEffect(() => {
    if (successSendToNetworkPodcast) {
      setPodcastsToShow((prevState) =>
        prevState.filter((doc) => doc.uid !== podcastIdToDelete)
      );
      onCloseModalHandler();
      dispatch(sendToNetworkPodcastInit());
      setPodcastIdToDelete(null);
    }
  }, [successSendToNetworkPodcast, loadingSendToNetworkPodcast]);

  const onEditAudienceHandler = ({ podcast }) => {
    onOpenModalHandler(ModalType.UPDATE_DOCUMENT_AUDIENCE, {
      podcast,
    });
  };

  const onEditDownloadsHandler = ({ podcast }) => {
    onOpenModalHandler(ModalType.UPDATE_DOCUMENT_DOWNLOADS, {
      podcast,
    });
  };

  const onClickLearnMoreHandler = (podcast) => {
    const podcastData = getPodcastData({ podcast });

    onOpenModalHandler(ModalType.PODCAST_SUMMARY, {
      podcast: podcastData,
      props: {
        onEdit: () => onEditAudienceHandler({ podcast: podcastData }),
        onEditDownloads: () => onEditDownloadsHandler({ podcast: podcastData }),
      },
    });
  };

  const onUpdateAudienceHandler = async ({ podcast, audienceEstimate }) => {
    dispatch(
      editPendingDocument({
        screenshot:
          podcast.network === networks.Spotify
            ? { userId: user.id, id: podcast.id }
            : podcast.screenshots,
        editedData: { monthlyListeners: audienceEstimate },
      })
    );

    setModal((prevState) => ({
      ...prevState,
      podcast: {
        ...prevState.podcast,
        screenshots: {
          ...prevState.podcast.screenshots,
          monthlyListeners: audienceEstimate,
        },
      },
    }));
  };

  const onEditDownloadsEstimateHandler = ({ podcast, downloadsEstimate }) => {
    dispatch(
      editPendingDocument({
        screenshot: podcast.screenshots,
        editedData: { downloadsPerEpisode: downloadsEstimate },
      })
    );

    setModal((prevState) => ({
      ...prevState,
      podcast: {
        ...prevState.podcast,
        screenshots: {
          ...prevState.podcast.screenshots,
          downloadsPerEpisode: downloadsEstimate,
        },
      },
    }));
  };

  const onCancelAudienceHandler = ({ podcast }) => {
    onOpenModalHandler(ModalType.PODCAST_SUMMARY, {
      podcast,
      props: {
        onEdit: () => onEditAudienceHandler({ podcast }),
        onEditDownloads: () => onEditDownloadsHandler({ podcast }),
      },
    });
  };

  const onClickAcceptHandler = useCallback((podcast) => {
    onOpenModalHandler(ModalType.ACCEPT_PODCAST, {
      podcastId: podcast.uid,
      podcast,
    });
  }, []);

  const onClickRejectHandler = useCallback((podcast) => {
    onOpenModalHandler(ModalType.REJECT_PODCAST_REASON, {
      podcast,
    });
  }, []);

  const onClickSendToNetwork = useCallback((podcast) => {
    onOpenModalHandler(ModalType.SEND_TO_NETWORK_PODCAST, {
      podcastId: podcast.uid,
      network:
        podcast.networks[0] === networks.Ossa
          ? networks.Spotify
          : networks.Ossa,
    });
  }, []);

  const onClickRejectReasonHandler = useCallback((podcast) => {
    onOpenModalHandler(ModalType.REJECT_PODCAST, {
      podcastId: podcast.uid,
      rejectionReason: podcast.rejectionReason,
    });
  }, []);

  const onAcceptPodcastHandler = useCallback(() => {
    setPodcastIdToDelete(modal.podcastId);

    dispatch(
      acceptPodcast({
        podcastId: modal.podcastId,
        newAudience: modal.podcast.screenshots,
        network: modal.podcast.networks[0],
      })
    );
  }, [modal]);

  const onRejectPodcastHandler = useCallback(() => {
    setPodcastIdToDelete(modal.podcastId);
    dispatch(
      rejectPodcast({
        podcastId: modal.podcastId,
        rejectionReason: modal.rejectionReason,
      })
    );
  }, [modal]);

  const onSendToNetworkPodcastHandler = useCallback(() => {
    setPodcastIdToDelete(modal.podcastId);
    dispatch(
      sendToNetworkPodcast({
        podcastId: modal.podcastId,
        network: modal.network,
      })
    );
  }, [modal]);

  const onClickSeeMoreHandler = useCallback(() => {
    setFetchMorePodcasts(true);
    dispatch(
      fetchPendingPodcasts({
        first,
        lastItemPagination,
        selectedSort,
        searchValue,
        network: user.network,
      })
    );
  }, [lastItemPagination]);

  const onChangeSelectedSorting = useCallback((sort) => {
    setPodcastsToShow([]);
    setSelectedSort(sort);
    setChangedSorting(true);
    setSearchValue('');
  }, []);

  const onChangeSearchValue = useCallback((event) => {
    setSearchValue(event.target.value);
  }, []);

  useEffect(() => {
    if (
      (modal.type === ModalType.UPDATE_DOCUMENT_AUDIENCE ||
        modal.type === ModalType.UPDATE_DOCUMENT_DOWNLOADS) &&
      !editPendingDocumentLoading &&
      editPendingDocumentSuccess
    ) {
      setFetchPodcastsChanges(true);

      const lastItemPaginationSort = changedSorting ? null : lastItemPagination;

      dispatch(
        fetchPendingPodcasts({
          first,
          lastItemPaginationSort,
          selectedSort,
          searchValue,
          network: user.network,
        })
      );

      onCancelAudienceHandler({ podcast: modal.podcast });
    }
  }, [
    editPendingDocumentLoading,
    editPendingDocumentSuccess,
    modal.type,
    lastItemPagination,
    changedSorting,
    modal.podcast,
    onCancelAudienceHandler,
  ]);

  const searchedPodcasts = searchValue
    ? filterPodcastsBySearchValue(podcastsToShow, searchValue)
    : podcastsToShow;

  return (
    <>
      {errorPendingPodcasts && (
        <Toast text={errorPendingPodcasts} id="Fetch podcasts error" />
      )}
      {errorRejectPodcast && (
        <Toast text={errorRejectPodcast} id="Reject podcast error" />
      )}
      {errorSendToNetworkPodcast && (
        <Toast text={errorRejectPodcast} id="Send to network podcast error" />
      )}
      {errorAcceptPodcast && (
        <Toast text={errorAcceptPodcast} id="Accept podcast error" />
      )}
      {successSendToNetworkPodcast && (
        <Toast
          type="success"
          text="The podcast has been transferred successfully!"
          id="Send to network podcast success"
        />
      )}
      <Modal
        isOpen={modal.type === ModalType.REJECT_PODCAST_REASON}
        onClose={onCloseModalHandler}
        className={classes.podcastModal}
      >
        <PodcastRejectReason
          podcast={modal.podcast}
          onAccept={onClickRejectReasonHandler}
          onCancel={onCloseModalHandler}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.PODCAST_SUMMARY}
        onClose={onCloseModalHandler}
        className={classes.podcastModal}
      >
        <PodcastFullInformation
          podcast={modal.podcast}
          onAccept={onClickAcceptHandler}
          onReject={onClickRejectHandler}
          {...(modal?.props || {})}
          approvingPodcast
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.UPDATE_DOCUMENT_AUDIENCE}
        onClose={() => onCancelAudienceHandler({ podcast: modal.podcast })}
      >
        {modal.type === ModalType.UPDATE_DOCUMENT_AUDIENCE && (
          <EditPodcastAudienceEstimate
            podcast={modal.podcast}
            onUpdate={onUpdateAudienceHandler}
            onCancel={onCancelAudienceHandler}
          />
        )}
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.UPDATE_DOCUMENT_DOWNLOADS}
        onClose={() => onCancelAudienceHandler({ podcast: modal.podcast })}
      >
        {modal.type === ModalType.UPDATE_DOCUMENT_DOWNLOADS && (
          <EditPodcastDownloadsEstimate
            podcast={modal.podcast}
            onUpdate={onEditDownloadsEstimateHandler}
            onCancel={onCancelAudienceHandler}
          />
        )}
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.ACCEPT_PODCAST}
        onClose={onCloseModalHandler}
      >
        <Confirmation
          title="Accept podcast"
          description="Are you sure you want to accept the selected podcast?"
          onAccept={onAcceptPodcastHandler}
          onCancel={onCloseModalHandler}
          loading={loadingAcceptPodcast}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.REJECT_PODCAST}
        onClose={onCloseModalHandler}
      >
        <Confirmation
          title="Reject podcast"
          description="Are you sure you want to reject the selected podcast?"
          onAccept={onRejectPodcastHandler}
          onCancel={onCloseModalHandler}
          loading={loadingRejectPodcast}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.SEND_TO_NETWORK_PODCAST}
        onClose={onCloseModalHandler}
      >
        <Confirmation
          title="Transfer podcast"
          description="Are you sure you want to transfer the selected podcast?"
          onAccept={onSendToNetworkPodcastHandler}
          onCancel={onCloseModalHandler}
          loading={loadingSendToNetworkPodcast}
        />
      </Modal>
      <div className={classes.container}>
        <div className={classes.content}>
          <Body
            letterCase={BodyLetterCase.Uppercase}
            spacing={BodySpacing.M}
            className={classes.title}
          >
            Podcasts for approval
          </Body>
          <div className={classes.manipulation}>
            <SelectInput
              options={sortingOptions}
              onChange={onChangeSelectedSorting}
              isOptionDisabled={(option) => option.value === selectedSort.value}
              value={selectedSort}
              className={classes.sort}
            />
            <Input
              className={classes.input}
              onChange={onChangeSearchValue}
              value={searchValue}
            />
          </div>
          {loadingPendingPodcasts && searchedPodcasts?.length === 0 ? (
            <Spinner className={classes.spinner} />
          ) : (
            <>
              {searchedPodcasts?.length === 0 ? (
                <Body
                  size={BodySize.XS}
                  spacing={BodySpacing.S}
                  className={classes.noPodcasts}
                >
                  There are no podcasts for approval
                </Body>
              ) : (
                <>
                  <div className={classes.podcasts}>
                    {searchedPodcasts?.map((podcast) => {
                      const { uid, podcastData } = podcast;

                      const { podcastTitle } = podcastData;

                      const key = `podcast-${uid}-${podcastTitle}`;

                      return (
                        <PodcastSummary
                          key={key}
                          podcast={podcast}
                          onClickLearnMore={() =>
                            onClickLearnMoreHandler(podcast)
                          }
                          onAccept={() => onClickAcceptHandler(podcast)}
                          onReject={() => onClickRejectHandler(podcast)}
                          onSendToNetwork={() => onClickSendToNetwork(podcast)}
                        />
                      );
                    })}
                  </div>
                  <div className={classes.buttons}>
                    {noMorePodcasts ? (
                      <Body
                        size={BodySize.XS}
                        spacing={BodySpacing.S}
                        className={classes.seeMoreButton}
                      >
                        There are no more pending podcasts
                      </Body>
                    ) : (
                      <Button
                        className={classes.seeMoreButton}
                        kind={ButtonKind.Secondary}
                        size={ButtonSize.S}
                        upperCase={false}
                        onClick={onClickSeeMoreHandler}
                        loading={
                          loadingPendingPodcasts && podcastsToShow.length > 0
                        }
                      >
                        See More Results
                      </Button>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PendingPodcasts;
